import React, { useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import {
    MdPause,
    MdPlayArrow,
    MdSkipNext,
    MdSkipPrevious,
} from 'react-icons/md';

import audios from '../audios';
import { Button } from './styled';
import { AudioPlayerContext } from './AudioPlayerContext';

const Player = styled.div`
    display: inline-block;
    margin-right: -4px;

    @media (max-width: 370px) {
        margin-right: -12px;
    }
`;

const TrackTitle = styled.span`
    vertical-align: middle;
    font-family: sans-serif;
    display: inline-block;
    line-height: 1.2;
    margin-right: 12px;
    text-align: right;

    max-width: calc(100vw - 128px);

    @media (max-width: 370px) {
        margin-right: 4px;
    }
`;

const TrackName = styled.span`
    display: inline-block;
    max-width: 100%;
    max-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
`;

const PlayerButtons = styled.span`
    display: inline-block;

    position: relative;
    top: -4px;
`;

export default function AudioPlayer() {
    return (
        <AudioPlayerContext.Consumer>
            {({ state, dispatch }) => (
                <Player>
                    <TrackTitle>
                        {audios[state.audioIndex].author}
                        <br />
                        <TrackName>{audios[state.audioIndex].name}</TrackName>
                        <br />
                    </TrackTitle>
                    <PlayerButtons>
                        <Button
                            onClick={() => dispatch({ type: 'previousTrack' })}
                            aria-label="Previous track"
                        >
                            <MdSkipPrevious size="24px" />
                        </Button>
                        {state.playing && (
                            <Button
                                onClick={() => dispatch({ type: 'pauseClick' })}
                                aria-label="Pause"
                            >
                                <MdPause size="32px" />
                            </Button>
                        )}
                        {!state.playing && (
                            <Button
                                onClick={() => dispatch({ type: 'playClick' })}
                                aria-label="Play"
                            >
                                <MdPlayArrow size="32px" />
                            </Button>
                        )}
                        <Button
                            onClick={() => dispatch({ type: 'nextTrack' })}
                            aria-label="Next track"
                        >
                            <MdSkipNext size="24px" />
                        </Button>
                    </PlayerButtons>
                    <Audio
                        onDurationChange={({ target: { duration } }) =>
                            dispatch({
                                type: 'duration',
                                duration,
                            })
                        }
                        onTimeUpdate={({ target: { currentTime } }) =>
                            dispatch({
                                type: 'timeUpdate',
                                currentTime,
                            })
                        }
                        onEnded={() => dispatch({ type: 'ended' })}
                        shouldPlay={state.shouldPlay}
                        onPlay={() => dispatch({ type: 'play' })}
                        onPause={() => dispatch({ type: 'pause' })}
                        src={audios[state.audioIndex].file}
                    />
                </Player>
            )}
        </AudioPlayerContext.Consumer>
    );
}

function Audio(props) {
    const audioElement = useRef(null);
    const { shouldPlay, src, ...otherProps } = props;

    useLayoutEffect(() => {
        if (shouldPlay) {
            audioElement.current.play();
        } else {
            audioElement.current.pause();
        }
    }, [src, shouldPlay]);

    return <audio src={src} ref={audioElement} {...otherProps} />;
}
