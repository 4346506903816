import styled from 'styled-components';

export const Button = styled.button`
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    color: #222222;
    transition: color 150ms;
    &:not(.focus-visible) {
        outline: none;
    }

    &:hover {
        color: #555555;
    }
`;

export const CiteTitle = styled.cite`
    font-style: italic;
`;

export const Centered = styled.div`
    max-width: 952px;
    padding: 0 16px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
`;

export const Page = styled(Centered)`
    position: relative;
    min-height: 100vh;
    padding-top: 112px;
    margin-bottom: -155px;
    padding-bottom: 156px;
`;
