import React from 'react';
import { IconContext } from 'react-icons/lib';
import Helmet from 'react-helmet';
import { Location } from '@reach/router';
import 'focus-visible';

import './layout.css';
import './underlay.css';

import AudioPlayerContextProvider from './AudioPlayerContext';
import Menu from './Menu';
import Footer from './Footer';

const iconSettings = { style: { verticalAlign: 'middle' } };

const Layout = ({ children }) => (
    <IconContext.Provider value={iconSettings}>
        <AudioPlayerContextProvider>
            <Location>
                {({ location }) => (
                    <>
                        <Helmet>
                            <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1, minimum-scale=1"
                            />
                        </Helmet>
                        <div
                            style={{ minHeight: '100vh', position: 'relative' }}
                        >
                            <Menu location={location} />
                            <main>{children}</main>
                            <Footer />
                        </div>
                    </>
                )}
            </Location>
        </AudioPlayerContextProvider>
    </IconContext.Provider>
);

export default Layout;
