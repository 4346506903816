import React, { createContext, useReducer } from 'react';
import audios from '../audios';

export const AudioPlayerContext = createContext({
    state: null,
    dispatch: () => {},
});

const initialState = {
    playing: false,
    duration: 0,
    currentTime: 0,
    audioIndex: 0,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'duration':
            return { ...state, duration: action.duration };
        case 'playClick':
            return { ...state, shouldPlay: true };
        case 'pauseClick':
            return { ...state, shouldPlay: false };
        case 'play':
            return { ...state, playing: true };
        case 'pause':
            return { ...state, playing: false };
        case 'timeUpdate':
            return { ...state, currentTime: action.currentTime };
        case 'nextTrack':
        case 'ended':
            return {
                ...state,
                audioIndex: (state.audioIndex + 1) % audios.length,
                currentTime: 0,
                shouldPlay: true,
            };
        case 'previousTrack':
            return {
                ...state,
                audioIndex:
                    (state.audioIndex - 1 + audios.length) % audios.length,
                currentTime: 0,
                shouldPlay: true,
            };
        case 'setIndex':
            return {
                ...state,
                audioIndex: action.index,
                currentTime: 0,
                shouldPlay: true,
            };
        default:
            throw new Error('Unknown action');
    }
};

const AudioPlayerContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AudioPlayerContext.Provider value={{ state, dispatch }}>
            {children}
        </AudioPlayerContext.Provider>
    );
};

export default AudioPlayerContextProvider;
