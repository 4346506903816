import bach from './bach-prelude-and-fugue-a-moll.mp3';
import shostakovichCisMoll from './shostakovich-cis-moll.mp3';
import shostakovichEDur from './shostakovich-e-dur.mp3';
import brahmsHMoll from './brahms-h-moll.mp3';
import brahmsGMoll from './brahms-g-moll.mp3';
import medtner38 from './medtner-op-38.mp3';
import medtner39matinata from './medtner-op-39-matinata.mp3';
import medtner39tragica from './medtner-op-39-tragica.mp3';

const audios = [
    {
        file: bach,
        author: 'J. S. Bach',
        name: 'Prelude and fugue a-moll (WTC 2)',
    },
    {
        file: shostakovichCisMoll,
        author: 'D. Shostakovich',
        name: 'Preludes op. 34 cis-moll',
    },
    {
        file: shostakovichEDur,
        author: 'D. Shostakovich',
        name: 'Preludes op. 34 E-dur',
    },
    {
        file: brahmsHMoll,
        author: 'J. Brahms',
        name: 'Zwei Rhapsodien op. 79 h-moll',
    },
    {
        file: brahmsGMoll,
        author: 'J. Brahms',
        name: 'Zwei Rhapsodien op. 79 g-moll',
    },
    {
        file: medtner38,
        author: 'N. Medtner',
        name: 'Sonata Reminiscenza op. 38',
    },
    {
        file: medtner39matinata,
        author: 'N. Medtner',
        name: 'Canzona Matinata op. 39',
    },
    {
        file: medtner39tragica,
        author: 'N. Medtner',
        name: 'Sonata Tragica op. 39',
    },
];

export default audios;
