import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { FaFacebookSquare, FaInstagram, FaYoutube } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import { Centered } from './styled';

const Footer = styled.footer`
    position: relative;
    background: #444444;
    margin-top: 48px;
    box-sizing: border-box;
    padding: 8px 0 16px;
    min-height: 104px;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -24px;
        height: 24px;
        width: 100%;
        background: linear-gradient(
            to top left,
            #444444 0%,
            #444444 50%,
            transparent 52%,
            transparent 100%
        );

        @media (max-width: 680px) {
            top: -16px;
            height: 16px;
        }
    }
`;

const FooterNav = styled.nav`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 600px) {
        display: block;
        padding: 0 24px;
    }
`;

const FooterNavSection = styled.div`
    min-width: 200px;
    box-sizing: border-box;
`;

const linkStyles = css`
    margin-right: 24px;
    color: white;
    font-family: sans-serif;
    font-size: 14px;
    text-decoration: none;
`;

const FooterLink = styled(Link)`
    ${linkStyles}

    @media (max-width: 600px) {
        margin-left: 30px;
    }
`;

const FooterExternalLink = styled.a`
    ${linkStyles}

    svg {
        margin-right: 10px;
    }
`;

const SiteFooter = () => {
    return (
        <Footer>
            <Centered>
                <FooterNav>
                    <FooterNavSection>
                        <FooterLink to="/#about">About</FooterLink>
                        <br />
                        <FooterLink to="/#upcoming">Upcoming</FooterLink>
                    </FooterNavSection>
                    <FooterNavSection>
                        <FooterLink to="/photos">Photos</FooterLink>
                        <br />
                        <FooterLink to="/recordings">Recordings</FooterLink>
                        <br />
                        <FooterLink to="/repertoire">Repertoire</FooterLink>
                    </FooterNavSection>
                    <FooterNavSection>
                        <FooterExternalLink href="https://www.facebook.com/anna.a.schreider">
                            <FaFacebookSquare size="20px" />
                            anna.a.schreider
                        </FooterExternalLink>
                        <br />
                        <FooterExternalLink href="https://www.instagram.com/anna.schreider/">
                            <FaInstagram size="20px" />
                            anna.schreider
                        </FooterExternalLink>
                    </FooterNavSection>
                    <FooterNavSection>
                        <FooterExternalLink href="https://www.youtube.com/@annaschreider9344">
                            <FaYoutube size="20px" />
                            @annaschreider9344
                        </FooterExternalLink>
                        <br />
                        <FooterExternalLink href="mailto:info@annaschreider.com">
                            <MdEmail size="20px" />
                            info@annaschreider.com
                        </FooterExternalLink>
                    </FooterNavSection>
                </FooterNav>
            </Centered>
        </Footer>
    );
};

export default SiteFooter;
