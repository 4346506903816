import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Navigation from './Navigation';
import AudioPlayer from './AudioPlayer';

const TopStrip = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    height: 64px;
    margin: 0;
    background: ${(props) =>
        props.atPageTop
            ? 'white'
            : `linear-gradient(
                to bottom right,
                white 0%,
                white 50%,
                transparent 51%,
                transparent 100%
            )`};
    z-index: 10;

    @media (max-width: 680px) {
        background: ${(props) =>
            props.atPageTop
                ? 'white'
                : `linear-gradient(
                    to bottom,
                    white 0%,
                    white 50%,
                    transparent 50%,
                    transparent 100%
                )`};

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 32px;
            width: 100%;
            background: ${(props) =>
                props.atPageTop
                    ? 'white'
                    : `linear-gradient(
                        to bottom right,
                        white 0%,
                        white 50%,
                        transparent 52%,
                        transparent 100%
                    )`};
            z-index: -1;
        }
    }
`;

const TopMenu = styled.menu`
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    display: inline-block;
    width: auto;
    height: 64px;
    margin: 0;
    font-family: sans-serif;
    padding: 16px;
    font-size: 14px;
    z-index: 25;
`;

const PlayerMenu = styled.div`
    position: ${(props) =>
        props.position === 'pageBottom' ? 'absolute' : 'fixed'};
    top: ${(props) => {
        switch (props.position) {
            case 'top':
                return 0;
            case 'bottom':
                return 'auto';
            case 'pageBottom':
                return 'calc(100vh - 64px)';
            default:
                return 0;
        }
    }};
    bottom: ${(props) => (props.position === 'bottom' ? '0' : 'auto')};
    right: 0;
    box-sizing: border-box;
    width: 100%;
    height: 64px;
    margin: 0;
    text-align: right;
    padding: 16px;
    font-size: 14px;
    background: ${(props) =>
        props.position === 'top'
            ? 'white'
            : `linear-gradient(
                to top left,
                white 0%,
                white 50%,
                transparent 51%,
                transparent 100%
            )`};
    z-index: 20;

    @media (max-width: 680px) {
        background: ${(props) =>
            props.position === 'top'
                ? 'white'
                : `linear-gradient(
                to top,
                white 0%,
                white 50%,
                transparent 50%,
                transparent 100%
            )`};

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 32px;
            width: 100%;
            background: ${(props) =>
                props.position === 'top'
                    ? 'white'
                    : `linear-gradient(
                    to top left,
                    white 0%,
                    white 50%,
                    transparent 52%,
                    transparent 100%
                )`};
            z-index: -1;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        height: 16px;
        width: 100%;
        background: linear-gradient(
            to bottom right,
            white 0%,
            white 47%,
            rgba(0, 0, 0, 0.3) 50%,
            transparent 53%,
            transparent 100%
        );
        z-index: -1;
        display: ${(props) => (props.position === 'top' ? 'block' : 'none')};

        @media (max-width: 680px) {
            bottom: -8px;
            height: 12px;
        }
    }
`;

const Menu = (props) => {
    const [playerPosition, setPlayerPosition] = useState('bottom');
    const isHome = props.location.pathname === '/';

    useEffect(() => {
        if (!isHome) {
            return;
        }

        const updatePlayerPosition = () => {
            try {
                const innerHeight = window.innerHeight;
                const pageHeight = document.getElementById('hero').offsetHeight;
                const scroll = window.scrollY;

                if (innerHeight < pageHeight - 2 && scroll < 2) {
                    setPlayerPosition('bottom');
                    return;
                }

                if (scroll < pageHeight - 64) {
                    setPlayerPosition('pageBottom');
                    return;
                }

                setPlayerPosition('top');
            } catch (e) {}
        };

        updatePlayerPosition();

        window.addEventListener('scroll', updatePlayerPosition);
        window.addEventListener('resize', updatePlayerPosition);

        return () => {
            window.removeEventListener('scroll', updatePlayerPosition);
            window.removeEventListener('resize', updatePlayerPosition);
        };
    }, [setPlayerPosition, isHome]);

    let actualPlayerPosition = !isHome ? 'top' : playerPosition;

    return (
        <>
            <TopStrip atPageTop={actualPlayerPosition === 'top'} />
            <TopMenu>
                <Navigation />
            </TopMenu>
            <PlayerMenu position={actualPlayerPosition}>
                <AudioPlayer />
            </PlayerMenu>
        </>
    );
};

export default Menu;
