import React, { useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import AriaModal from '@justfixnyc/react-aria-modal';
import { MdMenu, MdClose } from 'react-icons/md';
import { Link } from 'gatsby';

import { Button } from './styled';

const SideMenu = styled.nav`
    position: absolute;
    top: 0;
    left: 0;
    transform: ${(props) => (props.open ? 'none' : 'translateX(-232px)')};
    transition: transform 200ms;
    width: 200px;
    height: 256px;
    padding: 40px;
    background: white;
    z-index: 30;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -24px;
        width: 24px;
        height: 100%;
        background: linear-gradient(
            to bottom right,
            white 0%,
            white 50%,
            transparent 52%,
            transparent 100%
        );
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -24px;
        height: 24px;
        width: 100%;
        background: linear-gradient(
            to bottom right,
            white 0%,
            white 50%,
            transparent 52%,
            transparent 100%
        );
    }
`;

const MenuLink = styled(Link)`
    font-family: sans-serif;
    font-size: 18px;
    line-height: 2;
    color: #222222;
    text-decoration: none;
`;

const Navigation = () => {
    const [modalMounted, setModalMounted] = useState(false);
    const [modalHasEntered, setModalHasEntered] = useState(false);

    const closeModal = () => {
        setModalHasEntered(false);
        setTimeout(() => setModalMounted(false), 200);
    };

    return (
        <>
            <Button
                onClick={() => setModalMounted(true)}
                aria-label="Open menu"
            >
                <MdMenu size="32px" />
            </Button>
            <AriaModal
                mounted={modalMounted}
                titleText="Menu"
                onExit={closeModal}
                onEnter={() => setModalHasEntered(true)}
                underlayColor={false}
                underlayClass={classnames('underlay', {
                    hasEntered: modalHasEntered,
                })}
                scrollDisabled={false}
            >
                <SideMenu open={modalHasEntered}>
                    <Button
                        onClick={closeModal}
                        style={{
                            position: 'absolute',
                            top: '16px',
                            right: '0',
                        }}
                        aria-label="Close menu"
                    >
                        <MdClose size="24px" />
                    </Button>
                    <MenuLink to="/#about" onClick={closeModal}>
                        About
                    </MenuLink>
                    <br />
                    <MenuLink to="/#upcoming" onClick={closeModal}>
                        Upcoming
                    </MenuLink>
                    <br />
                    <MenuLink to="/photos" onClick={closeModal}>
                        Photos
                    </MenuLink>
                    <br />
                    <MenuLink to="/recordings" onClick={closeModal}>
                        Recordings
                    </MenuLink>
                    <br />
                    <MenuLink to="/repertoire" onClick={closeModal}>
                        Repertoire
                    </MenuLink>
                </SideMenu>
            </AriaModal>
        </>
    );
};

export default Navigation;
